/* Write your CSS here */
.app-logo {
  width: 100px;
  height: 100px;
}

.app-item {
  background-color: #ffffff;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  width: 200px;
  border-radius: 10px;
}

.app-name {
  font-size: 23px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .app-item {
    max-width: 170px;
  }
}
