/* Write your CSS here */
.tabs-container {
  list-style-type: none;
  display: flex;
  min-width: 40%;
  justify-content: space-around;
}

.bg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(#fff1eb, #ace0f9);
}
.search-icon {
  width: 30px;
}

.search-container {
  display: flex;
  justify-content: center;
  min-width: 70%;
}

.search {
  min-width: 50%;
  background-color: transparent;
  border: 1px solid #000000;
}

.apps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
}

@media screen and (max-width: 768px) {
}
