/* Write your CSS here */

.tab-item {
  background-color: transparent;
  margin-top: 20px;
  padding: 10px;
}

.tab-button {
  background-color: transparent;
  border: 0px;
  font-size: 24px;
  color: #7b8794;
  cursor: pointer;
}

.active-tab-button {
  color: #2563eb;
}
